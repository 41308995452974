import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as api from "../api/idderocloud-api";

export const getUserDevices = createAsyncThunk(
  "devices/getDevices",
  async (_, { rejectWithValue }) => {
    try {
      const devices = await api.getDevices();
      return devices;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) => {
      // do not emit more requests if previous is still in progress
      // https://redux-toolkit.js.org/api/createAsyncThunk#canceling-before-execution
      const state = getState();
      if (state.devices.loading) return false;
    },
  }
);

export const getDevice = createAsyncThunk(
  "devices/getDevice",
  async (id, { rejectWithValue }) => {
    try {
      const device = await api.getDevice(id);
      return device;
    } catch (err) {
      console.error("Failed to load device", err);
      return rejectWithValue(err)
    }
  }
);

export const updateUserDevice = createAsyncThunk(
  "devices/updateDevice",
  async ({ id, name, comment, updatekey }, { rejectWithValue }) => {
    try {
      const updatedDevice = await api.updateDevice({
        id,
        name,
        comment,
        updatekey,
      });
      return updatedDevice;
    } catch (err) {
      console.error("Failed to update device", err);
      return rejectWithValue(err)
    }
  }
);

export const changeDevicesView = createAction(
  "devices/changeDevicesView",
  function prepare(view) {
    localStorage.setItem("devices_view", view);
    return { payload: view };
  }
);

function getDeviceInitialState() {
  return {
    userDeviceReqId: null,
    userDevice: null,
    userDeviceLoading: false,
    userDeviceError: false,
    updateDeviceReqId: null,
    updateDeviceInProgress: false,
    updateDeviceError: null,
    updateDeviceFailed: false,
    updateDeviceSuccess: false,
  };
}

function getInitialState() {
  return {
    devices: null,
    devicesView: localStorage.getItem("devices_view") || "grid",
    loading: false,
    error: false,
    devicesLoaded: false,
    ...getDeviceInitialState(),
  };
}

const initialState = getInitialState();

// reducers
export const devicesSlice = createSlice({
  name: "devices",
  initialState,
  reducers: {
    initDeviceDetails: (state) => {
      Object.assign(state, getDeviceInitialState());
    },
  },
  extraReducers: (builder) => {
    builder.addCase(changeDevicesView, (state, action) => {
      state.devicesView = action.payload;
    });
    builder.addCase(getUserDevices.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserDevices.fulfilled, (state, action) => {
      state.devices = action.payload;
      state.devicesLoaded = true;
      state.loading = false;
      state.error = false;
    });
    builder.addCase(getUserDevices.rejected, (state) => {
      state.error = true;
      state.loading = false;
    });
    builder.addCase(getDevice.pending, (state, action) => {
      state.userDeviceReqId = action.meta.requestId;
      state.userDevice = null;
      state.userDeviceLoading = true;
      state.userDeviceError = false;
      state.updateDeviceSuccess = false;
    });
    builder.addCase(getDevice.fulfilled, (state, action) => {
      if (state.userDeviceReqId !== action.meta.requestId) return;
      state.userDeviceLoading = false;
      state.userDevice = action.payload;
    });
    builder.addCase(getDevice.rejected, (state, action) => {
      if (state.userDeviceReqId !== action.meta.requestId) return;
      state.userDeviceError = true;
      state.userDeviceLoading = false;
    });
    builder.addCase(updateUserDevice.pending, (state, action) => {
      state.updateDeviceReqId = action.meta.requestId;
      state.updateDeviceInProgress = true;
      state.updateDeviceError = null;
      state.updateDeviceFailed = false;
      state.updateDeviceSuccess = false;
    });
    builder.addCase(updateUserDevice.fulfilled, (state, action) => {
      if (state.updateDeviceReqId !== action.meta.requestId) return;
      state.updateDeviceInProgress = false;
      state.updateDeviceSuccess = true;
      state.userDevice = action.payload;
    });
    builder.addCase(updateUserDevice.rejected, (state, action) => {
      if (state.updateDeviceReqId !== action.meta.requestId) return;
      if (action.payload.type) {
        state.updateDeviceError = { type: action.payload.type };
      }
      state.updateDeviceInProgress = false;
      state.updateDeviceFailed = true;
      state.updateDeviceSuccess = false;
    });
    builder.addCase("auth/logout/fulfilled", () => {
      return { ...getInitialState() };
    });
  },
});

// actions
export const { initDeviceDetails } = devicesSlice.actions;

// selectors
export const getDevices = (state) => state.devices.devices;
export const devicesLoaded = (state) => state.devices.devicesLoaded;
export const getDevicesFailed = (state) => state.devices.error;
export const getDevicesView = (state) => state.devices.devicesView;
export const getUserDeviceState = ({ devices }) => ({
  userDevice: devices.userDevice,
  userDeviceLoading: devices.userDeviceLoading,
  userDeviceError: devices.userDeviceError,
  updateDeviceError: devices.updateDeviceError,
  updateDeviceInProgress: devices.updateDeviceInProgress,
  updateDeviceFailed: devices.updateDeviceFailed,
  updateDeviceSuccess: devices.updateDeviceSuccess,
});

export const devicesReducer = devicesSlice.reducer;
