import styles from "./AuthorizePage.module.scss";
import loginStyles from "./LoginPage.module.scss";
import sharedStyles from "../shared.module.scss";
import { useFormik } from "formik";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Link } from "../components";
import { useFormatMessage } from "../i18n";
import { initLogin, getLoginState, authorizeApp } from "../redux/auth-slice";
import {
  validateForm,
  getErrorMsg,
  isValid,
} from "../services/validation-service";
import { ValidatedInput } from "./components";
import { loginValidation } from "../shared/validation";
import cn from "classnames";
import { scrollToFirstValidationError } from "../utils";
import { useDispatch } from "react-redux";
import { ReactComponent as AmazonAlexaLogo } from "../assets/icons/Amazon_Alexa_blue_logo.svg";
import { useEffect } from "react";

export function AuthorizePage() {
  const [pristine, setPristine] = useState(true);
  const dispatch = useDispatch();

  const tr = useFormatMessage();

  useEffect(() => {
    dispatch(initLogin());
  }, [dispatch]);

  const { loginInProgress, loginFailed, invalidCredentials } =
    useSelector(getLoginState);

  const searchParams = new URLSearchParams(window.location.search);

  const authorizationParams = [...searchParams.entries()].reduce(
    (parameters, [parameter, value]) => {
      return {
        ...parameters,
        [parameter]: value,
      };
    },
    {}
  );

  const formik = useFormik({
    initialValues: {
      ...authorizationParams,
      email: "",
      password: "",
    },
    onSubmit: async (values) => {
      setPristine(false);
      if (isValid(validationErrors)) {
        const data = await dispatch(
          authorizeApp({
            ...authorizationParams,
            username: values.email,
            password: values.password,
          })
        );
        if (data.meta.requestStatus === "fulfilled") {
          window.location.href = data.payload.location;
        }
      } else {
        scrollToFirstValidationError();
      }
    },
  });

  const validationErrors = validateForm(
    { form: formik.values },
    loginValidation
  );

  const emailErrorMsg = getErrorMsg(validationErrors, "email");
  const passErrorMsg = getErrorMsg(validationErrors, "password");

  return (
    <div className={cn(loginStyles.loginPage, styles.authorizePage)}>
      <form onSubmit={formik.handleSubmit} className={sharedStyles.form}>
        <h4 className={cn(sharedStyles.title, sharedStyles.pageTitle)}>
          {tr("connect_with_alexa_title")}
        </h4>
        <div className={styles.alexaLogoContainer}>
          <AmazonAlexaLogo />
        </div>
        <div className={cn(sharedStyles.subtitle, sharedStyles.mainText)}>
          {tr("connect_with_alexa_brief")}
        </div>
        <ValidatedInput
          label={tr("email")}
          id="email-input"
          name="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          errors={emailErrorMsg}
          pristine={pristine}
        />
        <ValidatedInput
          label={tr("password")}
          id="password-input"
          name="password"
          type="password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          errors={passErrorMsg}
          pristine={pristine}
        />
        <div
          className={cn(
            loginStyles.containerForLinks,
            styles.containerForLinks
          )}
        >
          <div className={loginStyles.formSection}>
            <Link
              to={{
                pathname: "/password-recovery",
                state: { fromAuthorize: true },
              }}
            >
              {tr("i_have_lost_my_password")}
            </Link>
          </div>
        </div>
        <div className={sharedStyles.btnGroup}>
          <Button
            loading={loginInProgress}
            className={loginStyles.button}
            type="submit"
            title={tr("authorize")}
            disabled={loginInProgress}
            variant="primary"
          />
        </div>
      </form>
      {loginFailed && (
        <p style={{ color: "red", textAlign: "center" }}>
          {invalidCredentials
            ? tr("invalid_credentials")
            : tr("something_went_wrong")}
        </p>
      )}
    </div>
  );
}
