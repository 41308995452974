import styles from "./Button.module.scss";
import cn from "classnames";
import { Loader } from ".";
import sharedStyles from "../shared.module.scss";

export function Button({
  title,
  type,
  onClick,
  className,
  disabled,
  variant,
  size,
  icon,
  loading,
}) {
  const variants = {
    danger: styles.danger,
    light: styles.light,
    primary: styles.primary,
  };

  const sizeBtn = {
    content: {
      sm: styles.sm,
      lg: styles.lg,
    },
    text: {
      sm: styles.buttonTextSm,
      lg: styles.buttonTextLg,
    },
  };

  return (
    <button
      type={type}
      className={cn(
        styles.button,
        className,
        (disabled && styles.disabled) || variants[variant || "primary"],
        sizeBtn.content[size || "lg"]
      )}
      onClick={onClick}
      disabled={disabled}
    >
      <div className={styles.buttonContent}>
        {loading && <Loader className={styles.loader} />}
        {icon && (
          <div className={cn(styles.icon, loading && styles.hidden)}>
            {icon}
          </div>
        )}
        <span
          className={cn(
            styles.buttonText,
            sharedStyles.formText,
            sizeBtn.text[size || "lg"],
            icon && styles.buttonTextWithIcon,
            loading && styles.loading
          )}
        >
          {title}
        </span>
      </div>
    </button>
  );
}
