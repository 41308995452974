import { Link as RouterLink } from "react-router-dom";
import cn from "classnames";
import sharedStyles from "../shared.module.scss";

export function Link({ to, className, children, onClick }) {
  if (!to) {
    return (
      <div onClick={onClick} className={cn(sharedStyles.link, className)}>
        {children}
      </div>
    );
  }

  return (
    <RouterLink
      to={to}
      className={cn(sharedStyles.link, className, sharedStyles.formText)}
      onClick={onClick}
    >
      {children}
    </RouterLink>
  );
}
