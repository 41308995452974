export function validateRequired(value) {
  return Boolean(value);
}

export function validateEmail(value) {
  // https://emailregex.com/
  // eslint-disable-next-line no-control-regex
  return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i.test(
    value
  );
}

export function validatePassLowLetter(value) {
  return /^(?=.*[a-z])/.test(value);
}

export function validatePassUpperLeter(value) {
  return /^(?=.*[A-Z])/.test(value);
}

export function validatePassDigit(value) {
  return /^(?=.*[0-9])/.test(value);
}

export function validateMinLen(value) {
  const minLength = 8;
  return value.length >= minLength;
}

export function validateDifferrent(form, fieldNames) {
  return form[fieldNames[0]] !== form[fieldNames[1]];
}

export function validateEqual(form, fieldNames) {
  return form[fieldNames[0]] === form[fieldNames[1]];
}
