import styles from "./ValidatedCheckbox.module.scss";
import sharedStyles from "./shared.module.scss";
import { ValidationErrors } from "./ValidationErrors";
import cn from "classnames";
import { Checkbox } from "../../components";

export function ValidatedCheckbox({
  label,
  id,
  name,
  onChange,
  onBlur,
  type,
  value,
  errors,
  pristine,
}) {
  const showErrors = !pristine && errors.length !== 0;
  return (
    <div className={styles.checkBoxContainer}>
      <Checkbox
        label={label}
        id={id}
        name={name}
        type={type}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        labelClassName={showErrors && sharedStyles.errorLabel}
        className={cn(showErrors && sharedStyles.errorBorder)}
      />
      {!pristine && (
        <div className={cn(showErrors && sharedStyles.errorsBox)}>
          <ValidationErrors errors={errors} />
        </div>
      )}
    </div>
  );
}
