import { createAsyncThunk, createSlice, createAction } from "@reduxjs/toolkit";
import * as apiClient from "../api/idderocloud-api";
import { languages } from "../constants/select";
import { getQueryParam } from "../utils";

export const getUserDetails = createAsyncThunk(
  "settings/userData",
  async (_, { rejectWithValue }) => {
    try {
      const user = await apiClient.getUserData();
      return user;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateUser = createAsyncThunk(
  "settings/updateUser",
  async ({ name, country, lang }, { rejectWithValue }) => {
    try {
      await apiClient.updateUser({
        name,
        country,
        lang,
      });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const changePassword = createAsyncThunk(
  "settings/changePassword",
  async ({ old_password, new_password }, { rejectWithValue }) => {
    try {
      const data = await apiClient.changePassword({
        old_password,
        new_password,
      });
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const changeLanguage = createAction(
  "settings/changeLanguage",
  function prepare(lang) {
    localStorage.setItem("user_language", lang);
    return { payload: lang };
  }
);

function getUserSettingsInitialState() {
  return {
    userSettingsReqId: null,
    userSettings: {},
    userSettingsLoading: false,
    userSettingsFailed: false,

    settingsUpdateReqId: null,
    settingsUpdateLoading: false,
    settingsUpdated: false,
    settingUpdateFailed: false,

    changePasswordReqId: null,
    changePasswordPending: false,
    changePasswordUpdated: false,
    changePasswordFailed: false,
    changePassErrors: [],
  };
}

function isLangSupported(lang) {
  return languages.find((entry) => entry.value === lang);
}

function getLangFromQueryString() {
  const lang = getQueryParam("lang");
  if (lang && isLangSupported(lang)) {
    return lang;
  }
}

function getNavigatorLang() {
  if (navigator.languages && navigator.languages.length) {
    const navigatorLanguages = navigator.languages.map(
      (lang) => lang.split("-")[0]
    );
    const navigatorLang = navigatorLanguages.find(isLangSupported);
    return navigatorLang;
  }

  if (navigator.language) {
    const navigatorLang = navigator.language.split("-")[0];
    if (isLangSupported(navigatorLang)) return navigatorLang;
  }
}

function getInitialState() {
  /* If a lang is specified in the query string, we pick it up.
   * This is good enough for development and testing, however we don't make
   * any effort to keep the query string in sync after the initial parsing.
   */
  return {
    locale:
      getLangFromQueryString() ||
      localStorage.getItem("user_language") ||
      getNavigatorLang() ||
      "en",
    ...getUserSettingsInitialState(),
  };
}

const initialState = getInitialState();

// reducers
export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    initSettingsUpdate: (state) => {
      Object.assign(state, getUserSettingsInitialState());
    },
  },
  extraReducers: (builder) => {
    builder.addCase(changeLanguage, (state, action) => {
      state.locale = action.payload;
    });

    builder.addCase(getUserDetails.pending, (state, action) => {
      state.userSettingsReqId = action.meta.requestId;
      state.userSettingsLoading = true;
    });
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      if (state.userSettingsReqId !== action.meta.requestId) return;
      state.userSettings = action.payload;
      state.userSettingsLoading = false;
    });
    builder.addCase(getUserDetails.rejected, (state, action) => {
      if (state.userSettingsReqId !== action.meta.requestId) return;
      state.userSettingsFailed = true;
      state.userSettingsLoading = false;
    });

    builder.addCase(updateUser.pending, (state, action) => {
      state.settingsUpdateReqId = action.meta.requestId;
      state.settingsUpdateLoading = true;
      state.settingsUpdated = false;
      state.settingUpdateFailed = false;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      if (state.settingsUpdateReqId !== action.meta.requestId) return;
      state.settingsUpdated = true;
      state.settingsUpdateLoading = false;
      state.settingUpdateFailed = false;
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      if (state.settingsUpdateReqId !== action.meta.requestId) return;
      state.settingsUpdateLoading = false;
      state.settingUpdateFailed = true;
      state.settingsUpdated = false;
    });

    builder.addCase(changePassword.pending, (state, action) => {
      state.changePasswordReqId = action.meta.requestId;
      state.changePasswordPending = true;
      state.changePasswordFailed = false;
      state.changePasswordUpdated = false;
    });
    builder.addCase(changePassword.fulfilled, (state, action) => {
      if (state.changePasswordReqId !== action.meta.requestId) return;
      state.changePasswordPending = false;
      state.changePasswordUpdated = true;
      state.changePassErrors = [];
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      if (state.changePasswordReqId !== action.meta.requestId) return;
      state.changePasswordPending = false;
      state.changePasswordFailed = true;
      if (action.payload.type) {
        state.changePassErrors.push(action.payload.type);
      }
    });
    builder.addCase("auth/logout/fulfilled", () => {
      return { ...getInitialState() };
    });
  },
});

// actions
export const { initSettingsUpdate, initLanguage } = settingsSlice.actions;

// selectors
export const getLocale = (state) => state.settings.locale;
export const getUserData = (state) => ({
  user: state.settings.userSettings,
  userSettingsLoading: state.settings.userSettingsLoading,
  userSettingsFailed: state.settings.userSettingsFailed,
});
export const isSettingsUpdateFailed = (state) =>
  state.settings.settingUpdateFailed;
export const isSettingsUpdated = (state) => state.settings.settingsUpdated;
export const isSettingsUpdateLoading = (state) =>
  state.settings.settingsUpdateLoading;

export const getChangePasswordState = ({ settings }) => ({
  changePasswordPending: settings.changePasswordPending,
  changePasswordFailed: settings.changePasswordFailed,
  changePasswordUpdated: settings.changePasswordUpdated,
  serverErrors: settings.changePassErrors,
});

export const settingsReducer = settingsSlice.reducer;
