import { useFormatMessage } from "../../i18n";
import { useHistory } from "react-router";
import styles from "./AddNewDeviceGridView.module.scss";
import sharedStyles from "../../shared.module.scss";
import cn from "classnames";
import { ReactComponent as AddNewDeviceIcon } from "../../assets/icons/add-device-icon.svg";

export function AddNewDeviceGridView() {
  const tr = useFormatMessage();
  const history = useHistory();

  function handleAddDevice() {
    history.push("/help");
  }

  return (
    <div className={styles.addNewDevice} onClick={handleAddDevice}>
      <div className={cn(styles.addNewDeviceTitle, sharedStyles.secondaryText)}>
        {tr("new_device")}
      </div>
      <div className={styles.addNewDeviceImg}>
        <AddNewDeviceIcon />
      </div>
      <div className={cn(styles.addNewDeviceLink, sharedStyles.formText)}>
        {tr("click_here_for_creating_a_new_device")}
      </div>
    </div>
  );
}
