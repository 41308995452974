import React, { useState, useEffect, useCallback } from "react";
import { StatusPage } from ".";
import { useFormatMessage } from "../i18n";
import { useDispatch } from "react-redux";
import { confirmSignUp } from "../redux/auth-slice";
import { redirectToLogin } from "../services/auth-service";
import { getQueryParam } from "../utils";

export function ConfirmSignUpPage() {
  const tr = useFormatMessage();
  const dispatch = useDispatch();
  const confirmation_code = getQueryParam("cpcode");
  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("");

  const handleSubmit = useCallback(
    async (confirmation_code) => {
      const data = await dispatch(confirmSignUp({ confirmation_code }));

      if (data.meta.requestStatus === "fulfilled") {
        setMessage(
          tr(
            "everything_is_ok_just_click_on_the_confirmation_button_to_login_with_your_user_account"
          )
        );
        setVariant("success");
      } else {
        setVariant("error");
        setMessage(tr("sorry_but_your_user_cannot_be_enabled"));
      }
    },
    [dispatch, tr]
  );

  useEffect(() => {
    if (confirmation_code) {
      handleSubmit(confirmation_code);
    } else {
      redirectToLogin();
    }
  }, [confirmation_code, handleSubmit]);

  return (
    <>
      <StatusPage text={message} variant={variant} buttonTitle={"close"}/>
    </>
  );
}
