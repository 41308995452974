import { useEffect, useRef } from "react";
import disableScroll from "disable-scroll";
import { Button } from ".";
import { useOutsideClick } from "../hooks";
import styles from "./Dialog.module.scss";
import cn from "classnames";
import sharedStyles from "../shared.module.scss";

export function Dialog({ isOpen, onClose, content, buttons }) {
  const dialogRef = useRef();
  useOutsideClick(isOpen, dialogRef, onClose);

  const renderContent = content.split("\n").map((text, idx) => (
    <div key={idx} className={styles.dialogContentText}>
      {text}
    </div>
  ));

  useEffect(() => {
    if (isOpen) {
      disableScroll.on();
    } else {
      disableScroll.off();
    }
    return () => {
      disableScroll.off();
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className={styles.dialogOverlay}>
      <div className={styles.dialogWrapper} ref={dialogRef}>
        <div className={cn(styles.dialogContentWrapper, sharedStyles.mainText)}>
          {renderContent}
        </div>
        <div className={styles.dialogBtnGroup}>
          {buttons &&
            buttons.map((button, idx) => (
              <Button
                key={idx}
                variant={button.variant}
                title={button.title}
                size="sm"
                onClick={button.handler}
                className={styles.dialogBtn}
              />
            ))}
        </div>
      </div>
    </div>
  );
}
