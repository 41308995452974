import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DevicesGridView, DevicesListView } from ".";
import { Alert, ViewSwitch } from "../components";
import { REFRESH_INTERVAL } from "../constants/constants";
import { useFormatMessage } from "../i18n";
import {
  getDevices,
  getDevicesFailed,
  getDevicesView,
  getUserDevices,
  devicesLoaded,
} from "../redux/devices-slice";
import styles from "./MyDevicesPage.module.scss";

export function MyDevicesPage() {
  const dispatch = useDispatch();
  const tr = useFormatMessage();
  const devices = useSelector(getDevices);
  const devicesView = useSelector(getDevicesView);
  const devicesLoadingFailed = useSelector(getDevicesFailed);
  const initialLoadSuccessfull = useSelector(devicesLoaded);

  useEffect(() => {
    dispatch(getUserDevices());
  }, [dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getUserDevices());
    }, REFRESH_INTERVAL);
    return () => clearInterval(interval);
  }, [dispatch]);

  return (
    <div className={styles.devicesPage}>
      <ViewSwitch />
      {devicesLoadingFailed && !initialLoadSuccessfull ? (
        <Alert severity="error" className={styles.devicesError}>
          {tr("fail_getting_devices")}
        </Alert>
      ) : (
        <>
          {devicesView === "grid" && <DevicesGridView devices={devices} />}
          {devicesView === "list" && <DevicesListView devices={devices} />}
        </>
      )}
    </div>
  );
}
