import styles from "./Loader.module.scss";
import cn from "classnames";

export function Loader({ className }) {
  return (
    <div className={cn(styles.loader, className)}>
      <svg className={styles.circularLoader} viewBox="25 25 50 50">
        <circle className={styles.loaderPath} cx="50" cy="50" r="20"></circle>
      </svg>
    </div>
  );
}
