import en from "./en.json";
import de from "./de.json";
import es from "./es.json";
import fr from "./fr.json";
import deCountries from "./countries/countries-de.json";
import esCountries from "./countries/countries-es.json";
import enCountries from "./countries/countries-en.json";
import frCountries from "./countries/countries-fr.json";

export * from "./hooks";

export const locales = {
  en: { ...en, ...enCountries },
  de: { ...de, ...deCountries },
  es: { ...es, ...esCountries },
  fr: { ...fr, ...frCountries },
};

export function getLocaleMessages(locale) {
  const messages = locales[locale];
  if (!messages) {
    console.warn(`Unknown locale ${locale}, falling back to en`, locale);
    return locales.en;
  }
  return messages;
}
