import { useFormatMessage } from "../i18n";
import { countries } from "../constants/select";

export function useCountriesOptions() {
  const tr = useFormatMessage();

  const countriesOptions = countries.map((country, idx) => (
    <option value={country} key={`${country}-${idx}`}>
      {tr(`country.${country}`)}
    </option>
  ));
  return countriesOptions;
}
