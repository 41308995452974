import styles from "./Checkbox.module.scss";
import cn from "classnames";
import sharedStyles from "../shared.module.scss";

export function Checkbox({
  label,
  id,
  name,
  onChange,
  labelClassName,
  className,
  value,
}) {
  return (
    <div className={styles.checkboxWrapper}>
      <input
        checked={value}
        name={name}
        id={id}
        type="checkbox"
        className={cn(styles.input, className, sharedStyles.formText)}
        onChange={onChange}
      />
      {label && (
        <label className={cn(styles.label, labelClassName)} htmlFor={id}>
          <span className={styles.labelContent}>{label}</span>
        </label>
      )}
    </div>
  );
}
