import cn from "classnames";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { devicesLoaded } from "../redux/devices-slice";
import { AddNewDeviceGridView, DeviceCardGridView } from "./components";
import styles from "./DevicesGridView.module.scss";
import sharedStyles from "../shared.module.scss";

const SKELETONS_DEVICE_COUNT = 4;

export function DevicesGridView({ devices }) {
  const success = useSelector(devicesLoaded);

  const skeletons = Array(SKELETONS_DEVICE_COUNT)
    .fill()
    .map((_, idx) => <Skeleton key={idx} className={styles.skeleton} />);

  return (
    <div className={cn(styles.devicesGridView, sharedStyles.content)}>
      {success ? (
        <>
          {devices?.map((device) => (
            <DeviceCardGridView device={device} key={device.deviceId} />
          ))}
          <AddNewDeviceGridView />
        </>
      ) : (
        skeletons
      )}
    </div>
  );
}
