import axios from "axios";
import {
  getItemFromStorage,
  redirectToLogin,
  refreshToken,
} from "../services/auth-service";
import { oemValues } from "../constants/oem";

export const SERVER_ERRORS = {
  USER_ALREADY_EXISTS: "USER_ALREADY_EXISTS",
  DEVICE_ACCESS_DENIED: "DEVICE_ACCESS_DENIED",
  PRECONDITION_FAILED: "PRECONDITION_FAILED",
  NOT_FOUND: "NOT_FOUND",
  INCORRECT_PASSWORD: "INCORRECT_PASSWORD",
  INVALID_CREDENTIALS: "INVALID_CREDENTIALS",
  BAD_REQUEST: "BAD_REQUEST",
  GATEWAY_TIMEOUT: "GATEWAY_TIMEOUT",
};

function getApiBaseUrl() {
  const basePath =
    process.env.REACT_APP_API_BASE_URL || "MISSING_REACT_APP_API_BASE_URL";
  return `${basePath}/v1`;
}

function getVoiceApiBaseUrl() {
  const basePath =
    process.env.REACT_APP_VOICE_API_BASE_URL ||
    "MISSING_REACT_APP_VOICE_API_BASE_URL";
  return `${basePath}/v1`;
}

const apiClient = axios.create({
  baseURL: getApiBaseUrl(),
});

const voiceApiClient =
  oemValues.voiceControl && axios.create({ baseURL: getVoiceApiBaseUrl() });

function setupInterceptors(client) {
  client.interceptors.request.use(
    (config) => {
      const token = getItemFromStorage("access_token");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      console.error(error, "request error");
      return Promise.reject(error);
    }
  );

  client.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        try {
          originalRequest._retry = true;
          const accessToken = await refreshToken();
          originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;
          return client(originalRequest);
        } catch (error) {
          console.error("Failed to refresh token", error);
          redirectToLogin();
          return;
        }
      }
      return Promise.reject(error);
    }
  );
}

setupInterceptors(apiClient);

if (oemValues.voiceControl) {
  setupInterceptors(voiceApiClient);
}

export async function getTokens(user) {
  try {
    const { data } = await axios.post(`${getApiBaseUrl()}/token`, {
      ...user,
      grant_type: "password",
      client_id: process.env.REACT_APP_CLIENT_ID,
    });
    return { accessToken: data.access_token, refreshToken: data.refresh_token };
  } catch (err) {
    if (
      err.response.status === 401 &&
      err.response.data?.error === "invalid_grant"
    ) {
      const error = new Error("Invalid credentials");
      error.type = SERVER_ERRORS.INVALID_CREDENTIALS;
      throw error;
    }

    throw err;
  }
}

export async function refreshAuthToken(token) {
  const { data } = await axios.post(`${getApiBaseUrl()}/token`, {
    client_id: process.env.REACT_APP_CLIENT_ID,
    grant_type: "refresh_token",
    refresh_token: token,
  });
  return { accessToken: data.access_token, refreshToken: data.refresh_token };
}

export async function recoverPassword(email, recaptcha) {
  try {
    const response = await axios.post(`${getApiBaseUrl()}/forgot_password`, {
      client_id: process.env.REACT_APP_CLIENT_ID,
      username: email,
      recaptcha_response: recaptcha,
    });
    return response.data;
  } catch (err) {
    if (err.response.status === 404) {
      const error = new Error("User not found");
      error.type = SERVER_ERRORS.NOT_FOUND;
      throw error;
    }

    throw err;
  }
}

export async function confirmSignUp({ confirmation_code }) {
  const { data } = await axios.post(`${getApiBaseUrl()}/confirm_signup`, {
    confirmation_code,
  });
  return data;
}

export async function confirmForgotPassword({ confirmation_code }) {
  const { data } = await axios.post(
    `${getApiBaseUrl()}/confirm_forgot_password`,
    {
      confirmation_code,
    }
  );
  return data;
}

export async function confirmDeleteUser({ confirmation_code }) {
  const { data } = await axios.post(`${getApiBaseUrl()}/confirm_delete_user`, {
    confirmation_code,
  });
  return data;
}

export async function signup({
  username,
  password,
  name,
  country,
  lang,
  recaptcha_response,
}) {
  try {
    const { data } = await axios.post(`${getApiBaseUrl()}/signup`, {
      client_id: process.env.REACT_APP_CLIENT_ID,
      username,
      password,
      name,
      country,
      lang,
      recaptcha_response,
    });
    return data;
  } catch (err) {
    if (err.response.status === 422) {
      const error = new Error("User already exists");
      error.type = SERVER_ERRORS.USER_ALREADY_EXISTS;
      throw error;
    }

    throw err;
  }
}

export async function getUserData(email) {
  const { data } = await apiClient.get("/user", { username: email });
  return data;
}

export async function revokeToken(token, tokenType) {
  const { data } = await apiClient.post("/revoke", {
    token,
    token_type_hint: tokenType,
  });
  return data.revoked;
}

export async function getDevices() {
  const email = getItemFromStorage("user");
  const { data } = await apiClient.get("/devices", {
    username: email,
  });
  return data.devices;
}

export async function updateUser({ name, country, lang }) {
  const { data } = await apiClient.post("/user", {
    name,
    country,
    lang,
  });

  return data;
}

export async function changePassword({ new_password, old_password }) {
  try {
    const { data } = await apiClient.post("/password", {
      old_password,
      new_password,
    });
    return data;
  } catch (err) {
    if (err.response.status === 401) {
      const error = new Error("Invalid password");
      error.type = SERVER_ERRORS.INCORRECT_PASSWORD;
      throw error;
    }
  }
}

export async function deleteUser() {
  const { data } = await apiClient.post("/delete_user");
  return data;
}

export async function getDevice(id) {
  try {
    const { data } = await apiClient.get(`/devices/${id}`);
    return data.devices[0];
  } catch (error) {
    if (error.response.status === 403) {
      const error = new Error("You don't have access to this device");
      error.type = SERVER_ERRORS.DEVICE_ACCESS_DENIED;
      throw error;
    }
    throw error;
  }
}

export async function updateDevice({ name, comment, updatekey, id }) {
  try {
    const { data } = await apiClient.post(`/devices/${id}`, {
      friendly_name: name,
      comment,
      updatekey,
    });
    return data.devices[0];
  } catch (error) {
    if (error.response.status === 412) {
      const error = new Error("Precondition failed");
      error.type = SERVER_ERRORS.PRECONDITION_FAILED;
      throw error;
    }
    throw error;
  }
}

export async function authorize(authorizeData) {
  try {
    const { data } = await axios.post(
      `${getApiBaseUrl()}/authorize`,
      authorizeData
    );
    return data;
  } catch (err) {
    if (err.response.status === 400) {
      const error = new Error("Bad request");
      error.type = SERVER_ERRORS.INVALID_REQUEST;
      throw error;
    }
    if (err.response.status === 401) {
      const error = new Error("Invalid credentials");
      error.type = SERVER_ERRORS.INVALID_CREDENTIALS;
      throw error;
    }
    throw err;
  }
}

// Voice API

export async function refreshDevice(cloudId) {
  try {
    const { data } = await voiceApiClient.post(
      `voice/devices/${cloudId}/refresh`,
      {
        access_token: await refreshToken(),
        client_id: "iddero-mobile-app",
      }
    );
    return data;
  } catch (error) {
    if (error.response.status === 404) {
      const error = new Error("Device not found");
      error.type = SERVER_ERRORS.NOT_FOUND;
      throw error;
    }
    if (error.response.status === 504) {
      const error = new Error("Device timed out");
      error.type = SERVER_ERRORS.TIME_OUT;
      throw error;
    }
    throw error;
  }
}

export async function getControls(cloudId) {
  try {
    const { data } = await voiceApiClient.get(
      `voice/devices/${cloudId}/controls`
    );
    return data;
  } catch (err) {
    if (err.response.status === 404) {
      const error = new Error("Device not found");
      error.type = SERVER_ERRORS.NOT_FOUND;
      throw error;
    }
    throw err;
  }
}

export async function saveControl({ cloudId, control }) {
  try {
    const newData = {
      name: control.friendly_name,
      type: control.type,
    };
    const { data } = await voiceApiClient.post(
      `voice/devices/${cloudId}/controls/${control.id}`,
      newData
    );
    return data;
  } catch (err) {
    if (err.response.status === 400) {
      const error = new Error("Bad request");
      error.type = SERVER_ERRORS.INVALID_REQUEST;
      throw error;
    }
    throw err;
  }
}

export async function deleteControl({ cloudId, controlId }) {
  try {
    const { data } = await voiceApiClient.delete(
      `voice/devices/${cloudId}/controls/${controlId}`
    );
    return data;
  } catch (err) {
    if (err.response.status === 400) {
      const error = new Error("Bad request");
      error.type = SERVER_ERRORS.INVALID_REQUEST;
      throw error;
    }
    throw err;
  }
}

export async function deleteControls(cloudId) {
  try {
    const { data } = await voiceApiClient.delete(
      `voice/devices/${cloudId}/controls`
    );
    return data;
  } catch (err) {
    throw err;
  }
}
