import { useFormatMessage } from "../i18n";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import { changeDevicesView, getDevicesView } from "../redux/devices-slice";
import styles from "./ViewSwitch.module.scss";
import { ReactComponent as GridViewIcon } from "../assets/icons/grid-view-icon.svg";
import { ReactComponent as ListViewIcon } from "../assets/icons/list-view-icon.svg";
import sharedStyles from "../shared.module.scss";

export function ViewSwitch() {
  const tr = useFormatMessage();
  const dispatch = useDispatch();
  const devicesView = useSelector(getDevicesView);

  return (
    <div className={cn(styles.viewSwitch, sharedStyles.content)}>
      <h2 className={cn(styles.text, sharedStyles.pageTitle)}>
        {tr("my_devices")}
      </h2>
      <div className={styles.viewTypeGroup}>
        <GridViewIcon
          className={cn(
            styles.gridViewIcon,
            devicesView !== "grid" && styles.inactive
          )}
          onClick={() => dispatch(changeDevicesView("grid"))}
        />
        <ListViewIcon
          className={cn(
            styles.listViewIcon,
            devicesView !== "list" && styles.inactive
          )}
          onClick={() => dispatch(changeDevicesView("list"))}
        />
      </div>
    </div>
  );
}
