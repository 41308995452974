import { Select } from "../../components";
import sharedStyles from "./shared.module.scss";
import { ValidationErrors } from "./ValidationErrors";
import cn from "classnames";

export function ValidatedSelect({
  defaultName,
  name,
  onChange,
  onBlur,
  errors,
  pristine,
  options,
  label,
  optionsState,
  loading,
}) {
  const showErrors = !pristine && errors.length !== 0;
  return (
    <>
      <Select
        label={label}
        optionsState={optionsState}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        defaultName={defaultName}
        options={options}
        labelClassName={showErrors && sharedStyles.errorLabel}
        className={cn(showErrors && sharedStyles.errorBorder)}
        loading={loading}
      />
      {!pristine && (
        <div className={cn(showErrors && sharedStyles.errorsBox)}>
          <ValidationErrors errors={errors} />
        </div>
      )}
    </>
  );
}
