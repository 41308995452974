import { useFormik } from "formik";
import React, { useState } from "react";
import cn from "classnames";
import styles from "../UserSettingsPage.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { ValidatedInput } from "./ValidatedInput";
import { useFormatMessage } from "../../i18n";
import { Alert, Button, TextField } from "../../components";
import sharedStyles from "../../shared.module.scss";
import { validateForm, getErrorMsg } from "../../services/validation-service";
import { changePasswordValidation } from "../../shared/validation";
import {
  getUserData,
  changePassword,
  getChangePasswordState,
} from "../../redux/settings-slice";
import { scrollToFirstValidationError } from "../../utils";

export function ChangePasswordForm({ user }) {
  const tr = useFormatMessage();
  const dispatch = useDispatch();
  const {
    changePasswordFailed,
    changePasswordUpdated,
    changePasswordPending,
    serverErrors,
  } = useSelector(getChangePasswordState);
  const isValid = (obj) => Object.values(obj).every((err) => err.canResubmit);
  const { userSettingsLoading } = useSelector(getUserData);
  const [pristine, setPristine] = useState(true);

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      password: "",
      confirmPassword: "",
    },
    onSubmit: async (values) => {
      setPristine(false);
      const { password, currentPassword } = values;
      if (isValid(validationErrors)) {
        const result = await dispatch(
          changePassword({
            old_password: currentPassword,
            new_password: password,
          })
        );
        if (result.meta.requestStatus === "fulfilled") {
          setPristine(true);
          formik.resetForm();
        }
      } else {
        scrollToFirstValidationError();
      }
    },
  });

  const validationErrors = validateForm(
    { form: formik.values, serverErrors },
    changePasswordValidation
  );

  const passErrorMsg = getErrorMsg(validationErrors, "password");
  const confirmPassErrorMsg = getErrorMsg(validationErrors, "confirmPassword");
  const currentPassMsg = getErrorMsg(validationErrors, "currentPassword");

  return (
    <form
      onSubmit={formik.handleSubmit}
      className={cn(sharedStyles.form, sharedStyles.formSeparator)}
    >
      <TextField
        title={tr("user_account")}
        value={user.username}
        loading={userSettingsLoading}
      />
      <ValidatedInput
        label={tr("current_pasword")}
        id="currentPassword-input"
        name="currentPassword"
        type="password"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.currentPassword}
        pristine={pristine}
        errors={currentPassMsg}
        loading={userSettingsLoading}
      />
      <ValidatedInput
        label={tr("new_password")}
        id="password-input"
        name="password"
        type="password"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.password}
        pristine={pristine}
        errors={passErrorMsg}
        loading={userSettingsLoading}
      />
      <ValidatedInput
        label={tr("confirm_new_password")}
        id="confirmPasword-input"
        type="password"
        name="confirmPassword"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.confirmPassword}
        pristine={pristine}
        errors={confirmPassErrorMsg}
        loading={userSettingsLoading}
      />
      {changePasswordFailed && (
        <Alert severity="error">
          {tr("sorry_but_we_cannot_change_your_password")}
        </Alert>
      )}
      {changePasswordUpdated && (
        <Alert severity="success"> {tr("password_updated")}</Alert>
      )}
      <div className={cn(sharedStyles.btnGroup, styles.btnGroup)}>
        <Button
          type="submit"
          title={tr("save")}
          size="sm"
          variant="primary"
          disabled={userSettingsLoading || changePasswordPending}
          loading={changePasswordPending}
        />
      </div>
    </form>
  );
}
