import React, { useRef, useState } from "react";
import styles from "./Dropdown.module.scss";
import sharedStyles from "../shared.module.scss";
import cn from "classnames";
import { useOutsideClick } from "../hooks";

export function Dropdown({ renderHeader, renderBody, className }) {
  const [isOpened, setIsOpened] = useState(false);
  const popoverRef = useRef();
  useOutsideClick(isOpened, popoverRef, () => setIsOpened(false));

  const handleClick = React.useCallback(() => {
    setIsOpened((isOpened) => !isOpened);
  }, []);

  return (
    <div className={styles.dropdown}>
      {renderHeader(handleClick, isOpened)}
      {isOpened && (
        <div
          className={cn(
            styles.popoverWrapper,
            className,
            sharedStyles.formText
          )}
          ref={popoverRef}
        >
          {renderBody(handleClick)}
        </div>
      )}
    </div>
  );
}
