import * as api from "../api/idderocloud-api";

const items = {
  ACCESS_TOKEN: "access_token",
  REFRESH_TOKEN: "refresh_token",
  USER: "user",
  EMAIL: "email",
  REMEMBER_ME: "remember_me",
};

export const setTokens = ({ accessToken, refreshToken }, rememberMe) => {
  setItemToStorage(rememberMe, items.ACCESS_TOKEN, accessToken);
  setItemToStorage(rememberMe, items.REFRESH_TOKEN, refreshToken);
  localStorage.setItem(items.REMEMBER_ME, rememberMe);
};

export function setTokensAfterRefresh(tokens) {
  setTokens(tokens, isRememberMe());
}

export function getItemFromStorage(key) {
  let storage = getStorage(isRememberMe());
  return storage.getItem(key);
}

export function setItemToStorage(rememberMe, name, value) {
  let storage = getStorage(rememberMe);
  storage.setItem(name, value);
}

export function isRememberMe() {
  const rememberMe = localStorage.getItem(items.REMEMBER_ME);
  return JSON.parse(rememberMe || "false");
}

export function getStorage(rememberMe) {
  return rememberMe ? localStorage : sessionStorage;
}

export function hasAccessToken() {
  const accessToken = getItemFromStorage(items.ACCESS_TOKEN);
  return Boolean(accessToken);
}

export function setUser({ user, email }, rememberMe) {
  setItemToStorage(rememberMe, items.USER, user);
  setItemToStorage(rememberMe, items.EMAIL, email);
}

export function getUserFromStorage() {
  return {
    user: getItemFromStorage(items.USER),
    email: getItemFromStorage(items.EMAIL),
  };
}

export function clearStorages() {
  clearLocalStorage();
  clearSessionStorage();
}

export function clearLocalStorage() {
  Object.values(items).forEach((item) => localStorage.removeItem(item));
}

export function clearSessionStorage() {
  Object.values(items).forEach((item) => sessionStorage.removeItem(item));
}

export async function refreshToken() {
  const refreshToken = getItemFromStorage(items.REFRESH_TOKEN);
  const tokens = await api.refreshAuthToken(refreshToken);
  if (!tokens.accessToken) {
    throw new Error("Failed to get token from the backend");
  }
  setTokensAfterRefresh(tokens);
  return tokens.accessToken;
}

export async function revokeToken(tokenType) {
  const token = getItemFromStorage(tokenType);
  return await api.revokeToken(token, tokenType);
}

export function redirectToLogin() {
  clearStorages();
  window.location.href = "/login";
}
