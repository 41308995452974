const redirects = {
  "/app/#/register": "/signup",
  "/app/#/passreco": "/password-recovery",
};

function getMatchingRedirect(path) {
  const [, dest] =
    Object.entries(redirects).find(([source]) => path.indexOf(source) === 0) ||
    [];
  return dest;
}

export function redirectFromOldUrls() {
  const { pathname, hash } = window.location;
  const path = pathname + hash;
  const redirect = getMatchingRedirect(path);
  if (!redirect) return;
  window.location = redirect;
}
