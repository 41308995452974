import { useFormatMessage } from "../i18n";
import { languages } from "../constants/select";

export function useLanguagesOptions() {
  const tr = useFormatMessage();

  const languagesOptions = languages.map((language, idx) => (
    <option value={language.value} key={`${language.value}-${idx}`}>
      {tr(language.name)}
    </option>
  ));

  return languagesOptions;
}
