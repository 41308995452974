import { useSelector } from "react-redux";
import cn from "classnames";
import { LanguageDropdown, UserDropdown, Link } from ".";
import { isUserLoggedIn } from "../redux/auth-slice";
import styles from "./Header.module.scss";
import sharedStyles from "../shared.module.scss";

export function Header({ hideMenu }) {
  const isLoggedIn = useSelector(isUserLoggedIn);

  return (
    <header className={cn(styles.header, sharedStyles.content)}>
      <div className={styles.container}>
        <Link to="/" className={styles.homeLink}>
          <img src="/logo_header.png" alt="logo" className={styles.logo} />
        </Link>
        {!hideMenu && (isLoggedIn ? <UserDropdown /> : <LanguageDropdown />)}
      </div>
    </header>
  );
}
