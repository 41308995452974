import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import cn from "classnames";
import {
  PasswordRecoveryPage,
  LoginPage,
  SignUpPage,
  MyDevicesPage,
  DeviceSettings,
  UserSettingsPage,
  HelpPage,
  OfflinePage,
  ConfirmSignUpPage,
  ConfirmForgotPasswordPage,
  ConfirmDeletePage,
  AuthorizePage,
} from "./pages";
import { Header, Footer, PrivateRoute } from "./components";
import { getLocaleMessages } from "./i18n";
import { getLocale } from "./redux/settings-slice";
import styles from "./App.module.scss";
import { useEffect } from "react";
import { oemValues } from "./constants/oem";

const DEFUAULT_LOCALE = "en";

function isPublicRoute(route) {
  return [
    "/login",
    "/signup",
    "/terms-and-conditions",
    "/confirm_signup",
    "/password-recovery",
    "/help",
    "/authorize",
  ].includes(route);
}

function App() {
  const currentLocale = useSelector(getLocale);
  const messages = getLocaleMessages(currentLocale);
  const location = useLocation();
  const isPublic = isPublicRoute(location.pathname);

  useEffect(() => {
    if (isPublic) {
      document.body.classList.remove(styles.privateBackground);
    } else {
      document.body.classList.add(styles.privateBackground);
    }
  }, [isPublic]);

  return (
    <IntlProvider
      locale={currentLocale}
      defaultLocale={DEFUAULT_LOCALE}
      messages={messages}
    >
      <Switch>
        <Route path="/offline">
          <OfflinePage />
        </Route>
        <Route path="/">
          <div
            className={cn(
              styles.wrapper,
              !isPublic && styles.privateBackground
            )}
          >
            <div className={styles.container}>
              <Header></Header>
              <div className={styles.content}>
                <Switch>
                  <Route path="/login">
                    <LoginPage />
                  </Route>
                  <Route path="/(signup|terms-and-conditions)/">
                    <SignUpPage />
                  </Route>
                  <Route path="/password-recovery">
                    <PasswordRecoveryPage />
                  </Route>
                  <Route path="/confirm-signup">
                    <ConfirmSignUpPage />
                  </Route>
                  <Route path="/confirm-forgot-password">
                    <ConfirmForgotPasswordPage />
                  </Route>
                  <Route path="/confirm-delete-user">
                    <ConfirmDeletePage />
                  </Route>
                  <Route path="/help">
                    <HelpPage />
                  </Route>
                  <Route path="/authorize">
                    {oemValues.voiceControl ? (
                      <AuthorizePage />
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <PrivateRoute exact path="/" component={MyDevicesPage} />
                  <PrivateRoute path="/settings" component={UserSettingsPage} />
                  <PrivateRoute
                    path="/device-settings/:id"
                    component={DeviceSettings}
                  />
                  <Route path="*">
                    <Redirect to="/" />
                  </Route>
                </Switch>
              </div>
              <Footer />
            </div>
          </div>
        </Route>
      </Switch>
    </IntlProvider>
  );
}

export default App;
