import Skeleton from "react-loading-skeleton";
import styles from "./TextField.module.scss";
import sharedStyles from "../shared.module.scss";
import cn from "classnames";

export function TextField({ title, value, loading }) {
  return (
    <div className={styles.label}>
      <div className={cn(styles.title, sharedStyles.formText)}>
        {title}
      </div>
      <div className={cn(styles.valueWrapper, sharedStyles.formText)}>
        {loading ? (
          <Skeleton className={styles.value} />
        ) : (
          <div className={styles.value}>{value}</div>
        )}
      </div>
    </div>
  );
}
