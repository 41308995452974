export function validateForm({ form, serverErrors }, validations) {
  const result = validations
    .map((validation) => {
      const isValid = checkIsValid({ form, serverErrors }, validation);
      if (!isValid) {
        const result = JSON.parse(
          JSON.stringify({
            error: validation.errorMessage,
            field: validation.fieldName,
            fields: validation.fieldNames,
            canResubmit: validation.canResubmit,
          })
        );

        return result;
      }
      return null;
    })
    .filter(Boolean);
  return result;
}

function checkIsValid({ form, serverErrors }, validation) {
  if (validation.serverFn) {
    return validation.serverFn(serverErrors);
  }

  return validation.fieldName
    ? validation.fn(form[validation.fieldName])
    : validation.fn(form, validation.fieldNames);
}

export function getErrorMsg(errors, type) {
  return errors
    .map((er) => {
      if (er.fields) {
        return er.fields[0] === type ? er.error : null;
      }
      if (er.field === type) {
        return er.error;
      }
      return null;
    })
    .filter(Boolean);
}

export function isValid(obj) {
  return Object.values(obj).every((err) => err.canResubmit);
}
