import { useCallback } from "react";
import { useIntl } from "react-intl";
import en from "./en.json";

export function useLocale() {
  const intl = useIntl();
  return intl.locale;
}

export function useFormatMessage() {
  const intl = useIntl();

  const formatMessageFn = useCallback(
    (id, values, fallback) =>
      intl.formatMessage(
        { id, defaultMessage: fallback || en[id] || id },
        values
      ),
    [intl]
  );

  formatMessageFn.locale = intl.locale;
  formatMessageFn.intl = intl;

  return formatMessageFn;
}
