import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../api/idderocloud-api";
import { oemValues } from "../constants/oem";
import { logout } from "./auth-slice";
import { getUserDevices } from "./devices-slice";

export const refreshDeviceStatus = createAsyncThunk(
  "voice/refreshDeviceStatus",
  async (cloudId, { rejectWithValue }) => {
    try {
      const status = await api.refreshDevice(cloudId);
      return status;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (cloudId, { getState }) => {
      // do not emit more requests if previous is still in progress
      // https://redux-toolkit.js.org/api/createAsyncThunk#canceling-before-execution
      const state = getState();
      if (state.voiceControl.devices[cloudId].status === "checking")
        return false;
    },
  }
);

export const getControls = createAsyncThunk(
  "voiceControl/getControls",
  async (cloudId, { rejectWithValue }) => {
    try {
      const controls = await api.getControls(cloudId);
      return { cloudId, controls: controls.controls, zones: controls.zones };
    } catch (err) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (cloudId, { getState }) => {
      const state = getState();
      if (state.voiceControl.devices[cloudId].loading) return false;
    },
  }
);

export const addControl = createAsyncThunk(
  "voiceControl/addControl",
  async (deviceControl, { rejectWithValue }) => {
    try {
      const { cloudId, control } = deviceControl;
      const newControl = {
        ...JSON.parse(deviceControl.control.component),
        friendly_name: control.name,
        type: control.type,
      };
      await api.saveControl({ cloudId: cloudId, control: newControl });
      return { cloudId, newControl };
    } catch (err) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) => {
      const state = getState();
      if (state.voiceControl.addInProgress) return false;
    },
  }
);

export const deleteControl = createAsyncThunk(
  "voiceControl/deleteControl",
  async (deviceControl, { rejectWithValue }) => {
    try {
      await api.deleteControl(deviceControl);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) => {
      const state = getState();
      if (state.voiceControl.deleteInProgress) return false;
    },
  }
);

export const deleteControls = createAsyncThunk(
  "voiceControl/deleteControls",
  async (cloudId, { rejectWithValue }) => {
    try {
      await api.deleteControls(cloudId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
  {
    condition: (_, { getState }) => {
      const state = getState();
      if (state.voiceControl.deleteInProgress) return false;
    },
  }
);

function unselectedDevice() {
  return { status: "unselected", configured: [], not_configured: [] };
}

function getInitialState() {
  return {
    selected: unselectedDevice(),
    addInProgress: false,
    deleteInProgress: false,
    error: false,
    devices: {},
  };
}

function getVoiceCompatibleDevices(devices) {
  return devices
    ?.filter((device) => oemValues.voiceCompatible.includes(device.model))
    .reduce(
      (deviceList, device) => ({
        ...deviceList,
        [device.cloudId]: {
          status: "",
          loading: false,
          loaded: false,
          error: null,
          configured: [],
          not_configured: [],
          zones: [],
        },
      }),
      {}
    );
}

function getEditInitialState() {
  return {
    error: false,
    addInProgress: false,
    deleteInProgress: false,
  };
}

const initialState = getInitialState();

export const voiceControlSlice = createSlice({
  name: "voiceControl",
  initialState,
  reducers: {
    selectDevice: (state, action) => {
      state.selected = action.payload ? action.payload : unselectedDevice();
    },
    initEdit: (state) => {
      Object.assign(state, getEditInitialState());
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getControls.pending, (state, action) => {
      state.devices[action.meta.arg].loading = true;
    });
    builder.addCase(getControls.fulfilled, (state, action) => {
      const { cloudId, controls, zones } = action.payload;
      state.devices[cloudId] = {
        ...state.devices[cloudId],
        configured: controls.filter((component) =>
          component.hasOwnProperty("type")
        ),
        not_configured: controls.filter(
          (component) => !component.hasOwnProperty("type")
        ),
        zones,
        loading: false,
        loaded: true,
      };
      if (!zones.length) {
        state.devices[cloudId].status = "empty";
      }
    });
    builder.addCase(getControls.rejected, (state, action) => {
      state.devices[action.meta.arg].loading = false;
      state.devices[action.meta.arg].loaded = false;
      state.devices[action.meta.arg].status = "generic_error";
      if (action.payload.type) {
        state.devices[action.meta.arg].error = action.payload.type;
      }
    });
    builder.addCase(addControl.pending, (state) => {
      state.addInProgress = true;
    });
    builder.addCase(addControl.fulfilled, (state, action) => {
      const { cloudId, newControl } = action.payload;
      const controlIndex = state.devices[cloudId].configured.findIndex(
        (controls) => controls.id === newControl.id
      );
      controlIndex !== -1
        ? (state.devices[cloudId].configured[controlIndex] = newControl)
        : (state.devices[cloudId].configured = [
            ...state.devices[cloudId].configured,
            newControl,
          ]);
      state.devices[cloudId].not_configured = state.devices[
        cloudId
      ].not_configured.filter((controls) => controls.id !== newControl.id);
      state.addInProgress = false;
    });
    builder.addCase(addControl.rejected, (state, action) => {
      state.error = true;
      state.addInProgress = false;
      if (action.payload.type) {
        state.devices[action.meta.arg.cloudId].error = action.payload.type;
      }
    });
    builder.addCase(refreshDeviceStatus.pending, (state, action) => {
      state.devices[action.meta.arg].status = "checking";
    });
    builder.addCase(refreshDeviceStatus.fulfilled, (state, action) => {
      state.devices[action.meta.arg].status = action.payload.status;
    });
    builder.addCase(refreshDeviceStatus.rejected, (state, action) => {
      state.devices[action.meta.arg].status = "generic_error";
      if (action.payload.type) {
        state.devices[action.meta.arg].error = action.payload.type;
      }
    });
    builder.addCase(deleteControl.pending, (state) => {
      state.deleteInProgress = true;
    });
    builder.addCase(deleteControl.fulfilled, (state, action) => {
      state.error = false;
      state.deleteInProgress = false;
      const component = state.devices[action.meta.arg.cloudId].configured.find(
        (control) => control.id === action.meta.arg.controlId
      );
      const configured = state.devices[action.meta.arg.cloudId].configured;
      state.devices[action.meta.arg.cloudId].configured = configured.filter(
        (control) => control.id !== action.meta.arg.controlId
      );
      state.devices[action.meta.arg.cloudId].not_configured = [
        ...state.devices[action.meta.arg.cloudId].not_configured,
        component,
      ];
    });
    builder.addCase(deleteControl.rejected, (state, action) => {
      state.error = true;
      state.deleteInProgress = false;
      if (action.payload.type) {
        state.devices[action.meta.arg.cloudId].error = action.payload.type;
      }
    });
    builder.addCase(deleteControls.pending, (state) => {
      state.deleteInProgress = true;
    });
    builder.addCase(deleteControls.fulfilled, (state, action) => {
      state.deleteInProgress = false;
      state.devices[action.meta.arg].status = "";
    });
    builder.addCase(deleteControls.rejected, (state, action) => {
      state.deleteInProgress = false;
      state.devices[action.meta.arg].status = "generic_error";
    });
    builder.addCase(logout.fulfilled, () => {
      return { ...getInitialState() };
    });
    builder.addCase(getUserDevices.fulfilled, (state, action) => {
      state.devices = {
        ...getVoiceCompatibleDevices(action.payload),
        ...state.devices,
      };
    });
  },
});

export const { selectDevice, initEdit } = voiceControlSlice.actions;

export const getError = (state) => state.voiceControl.error;
export const getSelectedDeviceData = (state) => {
  return {
    ...state.voiceControl.selected,
    ...state.voiceControl.devices[state.voiceControl.selected.cloudId],
  };
};
export const getDeviceVoiceData = (cloudId) => (state) =>
  state.voiceControl.devices[cloudId];

export const getInProgressState = ({ voiceControl }) => {
  return {
    addInProgress: voiceControl.addInProgress,
    deleteInProgress: voiceControl.deleteInProgress,
  };
};

export const voiceControlReducer = voiceControlSlice.reducer;
