import React from "react";
import { Button, Footer, Header } from ".";
import styles from "./ErrorBoundary.module.scss";
import { ReactComponent as OopsIcon } from "../assets/icons/oops.svg";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  handleReloadClick() {
    // location.reload() will not work here,
    // we need to take user away from the buggy page.
    // If user is logged in - he'll be redirected to the home page.
    window.location = "/login";
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={styles.container}>
          <Header hideMenu />
          <div className={styles.content}>
            <div className={styles.iconContainer}>
              <OopsIcon className={styles.icon} />
            </div>
            <div className={styles.message}>Something went wrong</div>
            <Button
              type="button"
              title={"Reload"}
              variant="light"
              size="sm"
              className={styles.btn}
              onClick={this.handleReloadClick}
            />
          </div>
          <Footer className={styles.footer} />
        </div>
      );
    }

    return this.props.children;
  }
}
