/* eslint-disable */
import HC3KNX from "!!url-loader!../assets/icons/device-images/HC3-KNX.svg";
import HC3LKNX from "!!url-loader!../assets/icons/device-images/HC3L-KNX.svg";
import HC2KNX from "!!url-loader!../assets/icons/device-images/HC2-KNX.svg";
import HC2LKNX from "!!url-loader!../assets/icons/device-images/HC2L-KNX.svg";
import HC1iKNX from "!!url-loader!../assets/icons/device-images/HC1i-KNX.svg";
import SER1KNX from "!!url-loader!../assets/icons/device-images/SER1-KNX.svg";
import VERIP from "!!url-loader!../assets/icons/device-images/VERIP.svg";
/* eslint-enable */

export const deviceImages = {
  "HC3-KNX": HC3KNX,
  "HC3L-KNX": HC3LKNX,
  "HC2-KNX": HC2KNX,
  "HC2L-KNX": HC2LKNX,
  "HC1i-KNX": HC1iKNX,
  "SER1-KNX": SER1KNX,
  "SER3-KNX": SER1KNX,
  VERIP: VERIP,
  VERIND: VERIP,
  VERIP2: VERIP
};

export const devicesWithoutPlayIcon = ["VERIP", "VERIND", "VERIP2"];
