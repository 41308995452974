import styles from "./Input.module.scss";
import cn from "classnames";
import Skeleton from "react-loading-skeleton";
import sharedStyles from "../shared.module.scss";

export function Input({
  label,
  id,
  name,
  onChange,
  className,
  labelClassName,
  type,
  multiline,
  value,
  loading,
}) {
  return (
    <>
      {label && (
        <label
          className={cn(styles.label, labelClassName, sharedStyles.formText)}
          id={`${name}-label`}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      {!multiline ? (
        <>
          {loading ? (
            <Skeleton className={styles.input} />
          ) : (
            <input
              value={value}
              id={id}
              name={name}
              type={type}
              className={cn(styles.input, className, sharedStyles.formText)}
              onChange={onChange}
            />
          )}
        </>
      ) : (
        <>
          {loading ? (
            <Skeleton className={cn(styles.input, styles.textarea)} />
          ) : (
            <textarea
              value={value}
              id={id}
              name={name}
              className={cn(
                styles.input,
                className,
                styles.textarea,
                sharedStyles.formText
              )}
              onChange={onChange}
            />
          )}
        </>
      )}
    </>
  );
}
