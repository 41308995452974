import { useSelector } from "react-redux";
import { getLocale } from "../redux/settings-slice";
import { useFormatMessage } from "../i18n";
import { oemFlavor } from "../constants/oem";
import { InfoPage } from ".";

/* eslint-disable */
import termsEn from "!!raw-loader!../i18n/iddero/terms/terms-en.html";
import termsEs from "!!raw-loader!../i18n/iddero/terms/terms-es.html";
import termsEnEe from "!!raw-loader!../i18n/eelectron/terms/terms-en.html";
/* eslint-enable  */

function targetPage(lang) {
  switch (oemFlavor) {
    default:
    case "iddero":
      return lang === "es" ? termsEs : termsEn;
    case "eelectron":
      return termsEnEe;
  }
}

export function TermsPage({ className }) {
  const lang = useSelector(getLocale);
  const tr = useFormatMessage();

  return (
    <InfoPage
      title={tr("terms_header")}
      page={targetPage(lang)}
      pageClassName={className}
    />
  );
}
