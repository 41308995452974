import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import { useFormatMessage } from "../i18n";
import { Alert, Button, Dialog } from "../components";
import styles from "./UserSettingsPage.module.scss";
import sharedStyles from "../shared.module.scss";
import { ChangePasswordForm, UserSettingsForm } from "./components";
import { ReactComponent as DeleteBin } from "../assets/icons/delete-bin.svg";
import {
  getUserData,
  getUserDetails,
  initSettingsUpdate,
} from "../redux/settings-slice";

import { StatusPage } from ".";
import { deleteUser } from "../redux/auth-slice";

export function UserSettingsPage() {
  const tr = useFormatMessage();
  const { userSettingsLoading, user, userSettingsFailed } = useSelector(
    getUserData
  );
  const [stage, setStage] = useState("settings");
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(initSettingsUpdate());
    dispatch(getUserDetails());
  }, [dispatch]);

  async function handleSubmit() {
    setStage("success");
    await dispatch(deleteUser());
  }

  return (
    <>
      {stage === "success" && (
        <StatusPage
          text={tr(
            "account_deletion_in_progress_you_will_receive_an_email_soon_please_follow_the_instructions"
          )}
          variant="success"
          buttonTitle={"go_back"}
        />
      )}
      {stage === "settings" && (
        <div className={cn(sharedStyles.content, styles.userSettings)}>
          <div
            className={cn(sharedStyles.headerContainer, sharedStyles.content)}
          >
            <h1 className={sharedStyles.pageTitle}>{tr("settings")}</h1>
          </div>
          {userSettingsFailed && (
            <Alert severity="error" className={styles.userSettingsError}>
              {tr("fail_getting_user")}
            </Alert>
          )}
          <div className={styles.settingsWrapper}>
            <ChangePasswordForm user={user} />
            <UserSettingsForm user={user} />

            <div className={styles.containerForLink}>
              <Button
                type="submit"
                variant="danger"
                size="sm"
                className={styles.btnDanger}
                title={tr("delete")}
                icon={<DeleteBin />}
                disabled={userSettingsLoading}
                onClick={() => setShowModal(true)}
              />
            </div>
            <div
              className={cn(
                sharedStyles.overlay,
                !userSettingsFailed && sharedStyles.hidden
              )}
            ></div>
            <Dialog
              content={tr(
                "this_operation_is_not_reversible_are_you_sure_you_want_to_delete_your_account"
              )}
              isOpen={showModal}
              onClose={() => setShowModal(false)}
              buttons={[
                {
                  title: tr("no_go_back"),
                  handler: () => setShowModal(false),
                  variant: "light",
                },
                {
                  title: tr("yes_i_want_to_proceed"),
                  handler: () => handleSubmit(),
                  variant: "danger",
                },
              ]}
            />
          </div>
        </div>
      )}
    </>
  );
}
