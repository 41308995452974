import { configureStore } from "@reduxjs/toolkit";
import { authReducer } from "./auth-slice";
import { devicesReducer } from "./devices-slice";
import { settingsReducer } from "./settings-slice";
import { voiceControlReducer } from "./voiceControl-slice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    devices: devicesReducer,
    settings: settingsReducer,
    voiceControl: voiceControlReducer,
  },
});
