import { useEffect, useState } from "react";
import cn from "classnames";
import ReCAPTCHA from "react-google-recaptcha";
import { getLocale } from "../../redux/settings-slice";
import styles from "./Recaptcha.module.scss";
import { useFormatMessage } from "../../i18n";
import { useSelector } from "react-redux";
import errorStyles from "./ValidationErrors.module.scss";

export function Recaptcha({ rref, onChange, pristine, recaptcha }) {
  const locale = useSelector(getLocale);
  const tr = useFormatMessage();
  const isInvalid = recaptcha === "" || recaptcha === null;
  let error = "";
  const [shouldRender, setShouldRender] = useState(true);

  if (recaptcha === "") {
    error = tr("you_have_to_answer_the_captcha_before");
  } else if (recaptcha === null) {
    error = tr("captcha_verification_failed");
  }

  function handleChange(value) {
    onChange(value);
  }

  useEffect(() => {
    // trigger re-render to change the language
    setShouldRender(false);
    handleChange(null);
    // eslint-disable-next-line
  }, [locale]);

  useEffect(() => {
    if (!shouldRender) {
      setShouldRender(true);
    }
  }, [shouldRender]);

  return (
    <>
      <div className={styles.outer}>
        {shouldRender && (
          <ReCAPTCHA
            ref={rref}
            theme={"dark"}
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            onChange={handleChange}
            hl={locale}
          />
        )}
      </div>
      {!pristine && isInvalid && (
        <div className={cn(styles.errorsBox)}>
          <p className={cn(errorStyles.error)}>{error}</p>
        </div>
      )}
    </>
  );
}
