import cn from "classnames";
import styles from "./Alert.module.scss";
import sharedStyles from "../shared.module.scss";

export function Alert({ className, children, severity }) {
  const severities = {
    error: styles.error,
    success: styles.success,
  };
  return (
    <div
      className={cn(
        className,
        styles.alert,
        severities[severity],
        sharedStyles.formText
      )}
    >
      {children}
    </div>
  );
}
