import { useSelector } from "react-redux";
import { getLocale } from "../redux/settings-slice";
import { oemFlavor } from "../constants/oem";
import { InfoPage } from "./InfoPage";

/* eslint-disable */
import helpEn from "!!raw-loader!../i18n/iddero/help/help-en.html";
import helpEs from "!!raw-loader!../i18n/iddero/help/help-es.html";
import helpFr from "!!raw-loader!../i18n/iddero/help/help-fr.html";
import helpDe from "!!raw-loader!../i18n/iddero/help/help-de.html";
import helpEnEe from "!!raw-loader!../i18n/eelectron/help/help-en.html";
import helpEsEe from "!!raw-loader!../i18n/eelectron/help/help-es.html";
import helpFrEe from "!!raw-loader!../i18n/eelectron/help/help-fr.html";
import helpDeEe from "!!raw-loader!../i18n/eelectron/help/help-de.html";
/* eslint-disable */

function targetPage(lang) {
  switch (oemFlavor) {
    default:
    case "iddero":
      switch (lang) {
        default:
        case "en": return helpEn;
        case "es": return helpEs;
        case "fr": return helpFr;
        case "de": return helpDe;
      }
    case "eelectron":
      switch (lang) {
        default:
        case "en": return helpEnEe;
        case "es": return helpEsEe;
        case "fr": return helpFrEe;
        case "de": return helpDeEe;
      }
  }
}

export function HelpPage() {
  const lang = useSelector(getLocale);
  const page = targetPage(lang);

  return (
    <InfoPage
      page={page}
    />
  );
}
