import styles from "./ValidationErrors.module.scss";
import { useFormatMessage } from "../../i18n";
import sharedStyles from "../../shared.module.scss";
import cn from "classnames";

export function ValidationErrors({ errors }) {
  const tr = useFormatMessage();
  return errors.map((er, index) => {
    return index === 0 ? (
      <p className={cn(styles.error, sharedStyles.secondaryText)} key={er}>
        {`${tr("error")}: ${tr(er)}`}
      </p>
    ) : null;
  });
}
