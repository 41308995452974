import styles from "./Select.module.scss";
import cn from "classnames";
import Skeleton from "react-loading-skeleton";
import sharedStyles from "../shared.module.scss";

export function Select({
  name,
  onChange,
  options,
  label,
  defaultName,
  className,
  labelClassName,
  optionsState,
  loading,
}) {
  return (
    <div>
      <label
        className={cn(styles.label, labelClassName, sharedStyles.formText)}
      >
        {label}
      </label>
      {loading ? (
        <Skeleton className={cn(styles.select, styles.marginBottom)} />
      ) : (
        <div className={cn(styles.selectWrapper, styles.marginBottom)}>
          <select
            value={optionsState}
            name={name}
            onChange={onChange}
            className={cn(styles.select, className, sharedStyles.formText)}
          >
            {defaultName && <option disabled value="">{defaultName}</option>}
            {options}
          </select>
        </div>
      )}
    </div>
  );
}
