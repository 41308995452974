import { Formik } from "formik";
import React, { useRef, useState } from "react";
import { ValidatedSelect, ValidatedInput } from "./index";
import cn from "classnames";
import styles from "../UserSettingsPage.module.scss";
import { useFormatMessage } from "../../i18n";
import { Alert, Button, Select } from "../../components";
import sharedStyles from "../../shared.module.scss";
import { validateForm, getErrorMsg } from "../../services/validation-service";
import { userSettingsValidation } from "../../shared/validation";
import { useCountriesOptions, useLanguagesOptions } from "../../hooks";
import { useDispatch, useSelector } from "react-redux";
import {
  isSettingsUpdateFailed,
  isSettingsUpdated,
  changeLanguage,
  getUserData,
  isSettingsUpdateLoading,
} from "../../redux/settings-slice";
import { updateUser } from "../../redux/settings-slice";
import { updateUserName } from "../../redux/auth-slice";
import { isRememberMe, setUser } from "../../services/auth-service";
import { scrollToFirstValidationError } from "../../utils";

export function UserSettingsForm({ user }) {
  const tr = useFormatMessage();
  const { userSettingsLoading } = useSelector(getUserData);
  const settingUpdateFailed = useSelector(isSettingsUpdateFailed);
  const settingsUpdated = useSelector(isSettingsUpdated);
  const settingsUpdateLoading = useSelector(isSettingsUpdateLoading);
  const countriesOptions = useCountriesOptions();
  const languagesOptions = useLanguagesOptions();
  const formRef = useRef();

  const dispatch = useDispatch();

  const [pristine, setPristine] = useState(true);

  async function handleSubmit({ name, language, country }) {
    setPristine(false);
    if (!name || !country) {
      scrollToFirstValidationError(formRef.current);
      return;
    }

    const data = await dispatch(
      updateUser({
        name,
        lang: language,
        country,
      })
    );
    if (data.meta.requestStatus === "fulfilled") {
      dispatch(updateUserName(data.meta.arg.name));
      dispatch(changeLanguage(data.meta.arg.lang));
      setUser({ user: data.meta.arg.name }, isRememberMe());
    }
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        name: user?.name || "",
        country: user?.country || "",
        language: user?.lang || "",
      }}
      onSubmit={handleSubmit}
    >
      {(props) => {
        const validationErrors = validateForm(
          { form: props.values },
          userSettingsValidation
        );
        const nameErrorMsg = getErrorMsg(validationErrors, "name");
        const countryErrorMsg = getErrorMsg(validationErrors, "country");
        return (
          <form
            onSubmit={props.handleSubmit}
            className={cn(
              sharedStyles.form,
              sharedStyles.formSeparator,
              styles.userSettingsForm
            )}
            ref={formRef}
          >
            <ValidatedInput
              label={tr("name")}
              id="name-input"
              name="name"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.name}
              pristine={pristine}
              errors={nameErrorMsg}
              loading={userSettingsLoading}
            />

            <ValidatedSelect
              name="country"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              options={countriesOptions}
              label={tr("country")}
              pristine={pristine}
              errors={countryErrorMsg}
              loading={userSettingsLoading}
              optionsState={props.values.country || user.country}
            />
            <Select
              name="language"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              options={languagesOptions}
              label={tr("default_language")}
              pristine={pristine}
              loading={userSettingsLoading}
              optionsState={props.values.language || user.lang}
            />
            {settingUpdateFailed && (
              <Alert severity="error">{tr("something_went_wrong")}</Alert>
            )}
            {settingsUpdated && (
              <Alert severity="success"> {tr("user_updated")}</Alert>
            )}
            <div className={cn(sharedStyles.btnGroup, styles.btnGroup)}>
              <Button
                type="submit"
                title={tr("save")}
                size="sm"
                variant="primary"
                disabled={userSettingsLoading || settingsUpdateLoading}
                loading={settingsUpdateLoading}
              />
            </div>
          </form>
        );
      }}
    </Formik>
  );
}
