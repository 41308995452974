import { useCallback, useEffect } from "react";

export const useOutsideClick = (isOpen, ref, onClose) => {
  const handleOutsideClick = useCallback(
    (e) => {
      if (!isOpen) return;
      const path = e.path || (e.composedPath && e.composedPath());
      if (!path.includes(ref.current)) {
        onClose();
      }
    },
    [isOpen, onClose, ref]
  );

  useEffect(() => {
    document.body.addEventListener("click", handleOutsideClick);

    return () => {
      document.body.removeEventListener("click", handleOutsideClick);
    };
  }, [handleOutsideClick, isOpen]);
};
