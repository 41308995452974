import { useLocation } from "react-router-dom";
import { useScrollToTop } from "../hooks";

export function ScrollToTop() {
  const { pathname } = useLocation();

  useScrollToTop([pathname]);

  return null;
}
