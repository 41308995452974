import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import styles from "./LanguageDropdown.module.scss";
import { ReactComponent as DropdownIcon } from "../assets/icons/select-arrow.svg";
import { changeLanguage, getLocale } from "../redux/settings-slice";
import { languages } from "../constants/select";
import { Dropdown } from ".";
import { useFormatMessage } from "../i18n";

import es from "../assets/icons/flags/es.48.png";
import en from "../assets/icons/flags/gb.48.png";
import fr from "../assets/icons/flags/fr.48.png";
import de from "../assets/icons/flags/de.48.png";

const langIcons = { es, en, fr, de };

export function LanguageDropdown() {
  const dispatch = useDispatch();
  const tr = useFormatMessage();
  const locale = useSelector(getLocale);
  const remainingLanguages = languages.filter(
    (language) => language.value !== locale
  );

  function handleLanguageClick(language, handleClick) {
    dispatch(changeLanguage(language));
    handleClick();
  }

  return (
    <Dropdown
      renderHeader={(onHeaderClick, showOpen) => (
        <div className={styles.languageDropdownWrapper} onClick={onHeaderClick}>
          <FlagIcon
            lang={locale}
            alt="activeLanguage-flag"
            className={styles.icon}
          />
          <DropdownIcon
            alt="dropdown-icon"
            className={cn(styles.arrow, showOpen && styles.arrowOpen)}
          />
        </div>
      )}
      renderBody={(handleClick) => (
        <ul>
          {remainingLanguages.map(({ value, name }, idx) => (
            <li
              key={idx}
              className={styles.optionItem}
              onClick={() => handleLanguageClick(value, handleClick)}
            >
              <FlagIcon
                lang={value}
                className={styles.icon}
                alt={`${name}-icon`}
              />
              <p className={styles.optionItemName}>{tr(name)}</p>
            </li>
          ))}
        </ul>
      )}
      className={styles.popoverWrapper}
    />
  );
}

function FlagIcon({ lang, className }) {
  return (
    <img className={className} alt={`${lang}-icon`} src={langIcons[lang]} />
  );
}
