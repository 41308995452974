import { useEffect } from "react";
import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Link, Checkbox } from "../components";
import styles from "./LoginPage.module.scss";
import sharedStyles from "../shared.module.scss";
import { useFormatMessage } from "../i18n";
import { initLogin, login, getLoginState } from "../redux/auth-slice";
import { useHistory } from "react-router";
import {
  validateForm,
  getErrorMsg,
  isValid,
} from "../services/validation-service";
import { ValidatedInput } from "./components";
import { loginValidation } from "../shared/validation";
import cn from "classnames";
import { scrollToFirstValidationError } from "../utils";

export function LoginPage() {
  const tr = useFormatMessage();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    loginInProgress,
    loginFailed,
    isLoggedIn,
    invalidCredentials,
  } = useSelector(getLoginState);
  const [pristine, setPristine] = useState(true);

  useEffect(() => {
    if (!isLoggedIn) return;
    history.replace("/");
  }, [isLoggedIn, history]);

  useEffect(() => {
    dispatch(initLogin());
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
    onSubmit: (values) => {
      setPristine(false);
      const { email, password, rememberMe } = values;
      if (isValid(validationErrors)) {
        dispatch(login({ user: { username: email, password }, rememberMe }));
      } else {
        scrollToFirstValidationError();
      }
    },
  });

  const validationErrors = validateForm(
    { form: formik.values },
    loginValidation
  );

  const emailErrorMsg = getErrorMsg(validationErrors, "email");
  const passErrorMsg = getErrorMsg(validationErrors, "password");

  return (
    <div className={styles.loginPage}>
      <form onSubmit={formik.handleSubmit} className={sharedStyles.form}>
        <h4 className={cn(sharedStyles.title, sharedStyles.pageTitle)}>
          {tr("iddero_cloud_please_sign_in")}
        </h4>
        <ValidatedInput
          label={tr("email")}
          id="email-input"
          name="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          errors={emailErrorMsg}
          pristine={pristine}
        />

        <ValidatedInput
          label={tr("password")}
          id="password-input"
          name="password"
          type="password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          errors={passErrorMsg}
          pristine={pristine}
        />
        <div className={styles.containerForLinks}>
          <div className={styles.formSection}>
            <Checkbox
              label={tr("remember_me")}
              id="rememberMe"
              name="rememberMe"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.rememberMe}
            />
          </div>
          <div className={styles.formSection}>
            <Link to="/password-recovery">{tr("i_have_lost_my_password")}</Link>
            <Link to="/signup">{tr("i_am_a_new_user")}</Link>
          </div>
        </div>
        <Button
          loading={loginInProgress}
          className={styles.button}
          type="submit"
          title={tr("login")}
          disabled={loginInProgress}
          variant="primary"
        />
      </form>
      {loginFailed && (
        <p style={{ color: "red", textAlign: "center" }}>
          {invalidCredentials
            ? tr("invalid_credentials")
            : tr("something_went_wrong")}
        </p>
      )}
    </div>
  );
}
