import { useEffect } from "react";
import cn from "classnames";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { useFormatMessage } from "../i18n";
import sharedStyles from "../shared.module.scss";
import styles from "./DeviceSettings.module.scss";
import { Alert, Button, Input, TextField } from "../components";
import {
  getDevice,
  getUserDeviceState,
  initDeviceDetails,
  updateUserDevice,
} from "../redux/devices-slice";
import { getModelName } from "../utils";

const errorMessages = {
  PRECONDITION_FAILED: "fail_updating_device",
  SERVER_ERROR: "something_went_wrong",
};

export function DeviceSettings() {
  const tr = useFormatMessage();
  const dispatch = useDispatch();
  const {
    userDevice,
    updateDeviceError,
    updateDeviceFailed,
    updateDeviceInProgress,
    userDeviceLoading,
    userDeviceError,
    updateDeviceSuccess,
  } = useSelector(getUserDeviceState);
  const { id } = useParams();

  const errorMessage = tr(
    errorMessages[updateDeviceError?.type || "SERVER_ERROR"]
  );

  useEffect(() => {
    dispatch(initDeviceDetails());
    dispatch(getDevice(id));
  }, [dispatch, id]);

  return (
    <div className={cn(sharedStyles.content, styles.deviceSettings)}>
      <div className={cn(sharedStyles.headerContainer, sharedStyles.content)}>
        <h1 className={sharedStyles.pageTitle}>{tr("device_management")}</h1>
      </div>
      {userDeviceError && (
        <Alert severity="error" className={styles.deviceSettingsError}>
          {tr("fail_getting_device")}
        </Alert>
      )}
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: userDevice?.friendly_name || "",
          comment: userDevice?.comment || "",
        }}
        onSubmit={({ name, comment }) => {
          dispatch(
            updateUserDevice({
              id,
              updatekey: userDevice.updatekey,
              name,
              comment,
            })
          );
        }}
      >
        {(props) => (
          <>
            <form
              onSubmit={props.handleSubmit}
              className={cn(styles.form, sharedStyles.form)}
            >
              <TextField
                title={tr("model")}
                value={getModelName(userDevice?.model)}
                loading={userDeviceLoading}
              />
              <Input
                label={tr("name")}
                id="device-name"
                name="name"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.name}
                loading={userDeviceLoading}
              />
              <Input
                label={tr("comment")}
                id="device-comment"
                name="comment"
                multiline={true}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.comment}
                loading={userDeviceLoading}
              />
              {updateDeviceSuccess && (
                <Alert severity="success"> {tr("device_updated")}</Alert>
              )}
              {updateDeviceFailed && (
                <Alert severity="error">{errorMessage}</Alert>
              )}
              <div className={sharedStyles.btnGroup}>
                <Button
                  size="sm"
                  type="submit"
                  className={styles.button}
                  title={tr("save")}
                  disabled={userDeviceLoading || updateDeviceInProgress}
                  loading={updateDeviceInProgress}
                />
              </div>
              <div
                className={cn(
                  sharedStyles.overlay,
                  !userDeviceError && sharedStyles.hidden
                )}
              ></div>
            </form>
          </>
        )}
      </Formik>
    </div>
  );
}
