import cn from "classnames";
import Skeleton from "react-loading-skeleton";
import { AddNewDeviceListView, DeviceCardListView } from "./components";
import sharedStyles from "../shared.module.scss";
import styles from "./DevicesListView.module.scss";
import { useSelector } from "react-redux";
import { devicesLoaded } from "../redux/devices-slice";
import { useFormatMessage } from "../i18n";

const SKELETONS_DEVICE_COUNT = 6;

export function DevicesListView({ devices }) {
  const tr = useFormatMessage();
  const success = useSelector(devicesLoaded);

  const skeletons = Array(SKELETONS_DEVICE_COUNT)
    .fill()
    .map((_, idx) => (
      <Skeleton
        key={idx}
        className={cn(
          styles.skeleton,
          idx % 2 === 0 ? styles.even : styles.odd
        )}
      />
    ));

  return (
    <div
      className={cn(
        styles.devicesListView,
        sharedStyles.content,
        sharedStyles.formText
      )}
    >
      <div className={styles.devicesListViewWrapper}>
        <div className={styles.devicesListHeaders}>
          <div className={styles.modelHeader}>{tr("model")}</div>
          <div className={styles.nameHeader}>{tr("name")}</div>
        </div>
        {success ? (
          <>
            {devices?.map((device, idx) => (
              <DeviceCardListView
                device={device}
                key={device.deviceId}
                idx={idx}
              />
            ))}
            <AddNewDeviceListView />
          </>
        ) : (
          skeletons
        )}
      </div>
    </div>
  );
}
