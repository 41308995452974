import React, { useMemo } from "react";
import cn from "classnames";
import { useHistory } from "react-router-dom";
import styles from "./UserDropdown.module.scss";
import sharedStyles from "../shared.module.scss";
import { ReactComponent as SettingsIcon } from "../assets/icons/settings-icon.svg";
import { ReactComponent as HelpIcon } from "../assets/icons/help-icon.svg";
import { ReactComponent as LogoutIcon } from "../assets/icons/logout-icon.svg";
import { ReactComponent as DropdownIcon } from "../assets/icons/select-arrow.svg";
import { ReactComponent as HomeIcon } from "../assets/icons/home-icon.svg";
import { Dropdown } from ".";
import { useFormatMessage } from "../i18n";
import { useDispatch, useSelector } from "react-redux";
import { getUser, logout } from "../redux/auth-slice";
import { generateGravatarUrl } from "../utils";
import { AVATAR_SIZE } from "../constants/constants.js";

export function UserDropdown() {
  const history = useHistory();
  const tr = useFormatMessage();
  const dispatch = useDispatch();
  const { userName, email } = useSelector(getUser);

  const gravatarUrl = useMemo(() => generateGravatarUrl(email), [email]);

  return (
    <Dropdown
      renderHeader={(handleClick, showOpen) => {
        return (
          <div className={styles.userDropdown} onClick={handleClick}>
            <div className={styles.userDropdownWrapper}>
              <div className={cn(styles.userName, sharedStyles.formText)}>
                {userName}
              </div>
              <DropdownIcon
                alt="dropdown-icon"
                className={cn(styles.arrow, showOpen && styles.arrowOpen)}
              />
            </div>
            <img
              src={gravatarUrl}
              alt="avatar"
              className={styles.avatar}
              style={{ width: AVATAR_SIZE, height: AVATAR_SIZE }}
            />
          </div>
        );
      }}
      renderBody={(handleClick) => (
        <ul>
          <MenuItem
            name={tr("home")}
            onClick={() => {
              history.push("/");
              handleClick();
            }}
          >
            <HomeIcon className={styles.icon} alt="user-home-icon" />
          </MenuItem>
          <MenuItem
            name={tr("settings")}
            onClick={() => {
              history.push("/settings");
              handleClick();
            }}
          >
            <SettingsIcon className={styles.icon} alt="user-settings-icon" />
          </MenuItem>
          <MenuItem
            name={tr("help")}
            onClick={() => {
              history.push("/help");
              handleClick();
            }}
          >
            <HelpIcon className={styles.icon} alt="user-help-icon" />
          </MenuItem>
          <hr />
          <MenuItem
            name={tr("logout")}
            onClick={() => {
              dispatch(logout()).then(() => {
                history.push("/login");
              });
              handleClick();
            }}
          >
            <LogoutIcon className={styles.icon} alt="user-logout-icon" />
          </MenuItem>
        </ul>
      )}
    />
  );
}

function MenuItem({ onClick, name, children, className }) {
  return (
    <li className={cn(styles.optionItem, className)} onClick={onClick}>
      <p className={styles.optionItemName}>{name}</p>
      {children}
    </li>
  );
}
