import { getQueryParam } from "../utils";
import styles from "./OfflinePage.module.scss";
import { useEffect, useState } from "react";

// testing url: http://localhost:3000/offline?domain=1c5d620326bddfe2.idderocloud.com&where=be
// If pk_where is present in the query string, then we are being
// shown inside a pagekite.net frame. Redirect to a non-framed page.
// Note: This should not happen anymore after the changes introduced
// by PK on Nov 2017 (see #1726)
export function OfflinePage() {
  const [status, setStatus] = useState("");
  const [cloudId, setCloudId] = useState("");

  useEffect(() => {
    const pkWhere = getQueryParam("pk_where");
    if (pkWhere) {
      const pkDomain = getQueryParam("pk_domain", "");
      window.parent.location = `${process.env.REACT_APP_BASE_URL}/offline?domain=${pkDomain}&where=${pkWhere}`;
      return;
    }

    const domain = getQueryParam("domain", "");
    const where = getQueryParam("where", "");
    const cloudId = domain.split(".")[0] || "unspecified";
    const status =
      where.toLowerCase() === "be"
        ? "Device is available, but not responding"
        : "Device is offline";

    setCloudId(cloudId);
    setStatus(status);
  }, []);

  return (
    <div className={styles.offlinePage}>
      <a href={process.env.REACT_APP_BASE_URL + "/"} className={styles.logo}>
        <img src="/logo_offline.png" alt="logo"></img>
      </a>
      <p className={styles.info}>
        <div>Cloud ID: {cloudId}</div>
        <div>{status}</div>
      </p>
    </div>
  );
}
