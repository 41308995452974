import { useFormatMessage } from "../../i18n";
import { useHistory } from "react-router-dom";
import styles from "./AddNewDeviceListView.module.scss";
import sharedStyles from "../../shared.module.scss";
import cn from "classnames";
import { ReactComponent as AddNewDeviceIcon } from "../../assets/icons/add-device-icon.svg";

export function AddNewDeviceListView() {
  const tr = useFormatMessage();
  const history = useHistory();

  function handleAddNewDevice() {
    history.push("/help");
  }

  return (
    <div className={styles.addNewDevice}>
      <div className={styles.addNewDeviceWrapper}>
        <div
          className={styles.addNewDeviceContent}
          onClick={handleAddNewDevice}
        >
          <div className={styles.addNewDeviceImg}>
            <AddNewDeviceIcon />
          </div>
          <div className={cn(styles.addNewDeviceLink, sharedStyles.formText)}>
            {tr("click_here_for_creating_a_new_device")}
          </div>
        </div>
      </div>
    </div>
  );
}
