import { useFormatMessage } from "../i18n";
import { Link } from "../components";
import styles from "./Footer.module.scss";
import cn from "classnames";
import sharedStyles from "../shared.module.scss";
import { oemValues } from "../constants/oem";

export function Footer({ className }) {
  const tr = useFormatMessage();

  return (
    <footer className={cn(styles.footer, className)}>
      <div className={cn(styles.footerLinks)}>
        <a
          href={"https://" + oemValues.footerLink}
          className={cn(
            styles.copyright,
            sharedStyles.link,
            sharedStyles.secondaryText
          )}
        >
          © {oemValues.footerLink}
        </a>
        <span style={{ whiteSpace: "pre" }}> &mdash; </span>
        <Link
          to="/terms-and-conditions"
          className={cn(sharedStyles.link, sharedStyles.secondaryText)}
        >
          {tr("terms_and_conditions")}
        </Link>
      </div>
    </footer>
  );
}
